import request from "@/utils/request";

export function getGradeList() {
  return request({
    method: "POST",
    url: "absen/grade-list"
  });
}

export function getClassList(data) {
  return request({
    method: "POST",
    url: "class/list",
    data: data
  });
}

export function getSchoolYearList() {
  return request({
    method: "POST",
    url: "school_year/all_list"
  });
}

export function getReports(data) {
  return request({
    method: "POST",
    url: "absen/monthly-report",
    data: data
  });
}

export function getDetailReport(data) {
  return request({
    method: "POST",
    url: "absen/detail-report",
    data: data
  });
}

export function masterAbsent() {
  return request({
    method: "post",
    url: "absen/list-in"
  });
}

export function myRecapPerSubjects(data) {
  return request({
    method: "post",
    url: "lesson-absence/my-recap",
    data: data
  });
}
