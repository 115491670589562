<template>
  <div class="mx-4">
    <v-row class="mt-3">
      <v-col v-if="prefix === 'student'" cols="12" :sm="showRoleType ? 8 : 6">
        <div class="d-flex align-center flex-wrap">
          <v-select
            v-model="grade"
            v-if="checkPrivilages('RRC05')"
            :label="$t('recap_absent.select_grade')"
            :items="gradeList"
            :loading="loadingGrade"
            item-value="id"
            item-text="grade"
            outlined
            dense
            hide-details
            class="select-200 d-inline-block mr-3 mt-2"
            @change="onChangeGrade"
          />
          <v-select
            v-model="idClass"
            v-if="checkPrivilages('RRC06')"
            :label="$t('recap_absent.select_class')"
            :items="classList"
            :loading="loadingClass"
            item-value="id"
            item-text="name"
            outlined
            dense
            hide-details
            class="select-225 d-inline-block mr-3 mt-2"
            @change="onChangeClass"
          />
          <div class="subtitle-2 font-weight-bold ml-2">
            {{ rowData.length }} {{ " " + name }}
          </div>
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="12" class="mt-2" :sm="showRoleType ? 4 : 6">
        <div
          class="d-flex"
          :class="{ 'justify-end': $vuetify.breakpoint.smAndUp }"
        >
          <v-text-field
            v-if="!showRoleType && checkPrivilages('RRC04')"
            :label="$t('app.search')"
            :error="validSearch"
            :hide-details="!validSearch"
            :error-messages="
              validSearch ? $t('recap_absent.search_valid_rules') : null
            "
            class="mr-2"
            autocomplete="off"
            clearable
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            style="max-width: 250px"
            @input="handleSearchData"
          />
          <v-btn
            v-if="rowData.length !== 0"
            :loading="loadingDownloadExcel"
            :disabled="listMasterAbsent.length == 0"
            class="gradient-primary caption mr-2"
            dark
            depressed
          >
            <download-excel
              :fetch="downloadExcel"
              :fields="fields"
              :name="excelFileName()"
              :worksheet="excelFileName('worksheet')"
            >
              <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
                {{ $t("app.download") }}
              </span>
              <v-icon>mdi-cloud-download</v-icon>
            </download-excel>
          </v-btn>
          <v-select
            v-model="idMonth"
            v-if="checkPrivilages('RRC07')"
            :items="$t('absent.month')"
            :label="$t('recap_absent.select_months')"
            :disabled="prefix !== 'student' ? false : isMonthDisabled"
            item-value="id"
            item-text="value"
            outlined
            dense
            hide-details
            class="select-150 d-inline-block"
            append-icon="mdi-calendar-range"
            @change="changeMonth"
          />
        </div>
      </v-col>
    </v-row>

    <div class="mt-5 mb-5" v-if="checkPrivilages('RRC08')">
      <!-- <div v-for="info in colorsAbsent" :key="info.id" class="mr-5 d-flex mb-2"> -->
      <v-row justify="space-between">
        <v-col cols="12" sm="2">
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[2].color }"
              class="color-info mr-2"
            />
            <span>
              {{ colorsAbsent[2].name }}
            </span>
          </div>
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[8].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[8].name }}
          </div>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[4].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[4].name }}
          </div>
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[5].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[5].name }}
          </div>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[6].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[6].name }}
          </div>
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[7].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[7].name }}
          </div>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[1].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[1].name }}
          </div>
          <div class="d-flex flex-row mb-2">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[3].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[3].name }}
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="d-flex flex-row">
            <div
              v-bind:style="{ backgroundColor: colorsAbsent[0].color }"
              class="color-info mr-2"
            />
            {{ colorsAbsent[0].name }}
          </div>
        </v-col>
      </v-row>
      <!-- </div> -->
    </div>

    <v-alert
      v-if="
        checkPrivilages('RRC09') && rowData.length == 0 && idClass && !loading
      "
      color="warning"
      dense
      dark
      class="text-center"
      >{{ $t("app.data_not_found") }}</v-alert
    >

    <div v-else-if="checkPrivilages('RRC09') && rowData.length > 0 && !loading">
      <ag-grid-vue
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
        style="height: 400px; width: 100%"
        class="ag-theme-alpine"
      />
    </div>

    <DialogDetail
      :model="modelDialog"
      :itemAbsent="itemAbsent"
      @isOpen="isDialog"
    />
  </div>
</template>

<script>
import {
  getReports,
  masterAbsent,
  getDetailReport
} from "@/api/admin/absent/recapAbsent";
import {
  get_school_year_list,
  get_class_list,
  get_grade_list
} from "@/api/admin/schoolClass";
import { checkPrivilages } from "@/utils/privilages";
import i18n from "@/i18n";
import { colorAbsent, colorsInfo } from "@/utils/master";
import DialogDetail from "./DialogDetail";
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import moment from "moment/moment";
moment.locale("id");
let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    init: Boolean,
    prefix: {
      type: String,
      required: true
    },
    employeeType: String,
    name: {
      type: String,
      required: true
    },
    searchData: {
      type: String,
      required: true
    },
    showRoleType: Boolean
  },
  components: {
    AgGridVue,
    DialogDetail
  },
  computed: {
    ...mapGetters(["g_roles"]),
    validSearch() {
      return this.idClass == "" && this.search != "" ? true : false;
    }
  },
  data() {
    return {
      fields: {},
      loadingDownloadExcel: false,
      colorsAbsent: colorsInfo,
      isMonthDisabled: true,
      headerDate: null,
      modelDialog: false,
      monthTotal: [],
      classType: "",
      idClass: "",
      defaultColDef: null,
      columnDefs: null,
      rowData: [],
      modelClass: null,
      searchValid: this.$route.query.tab,
      dataStudent: [],
      loading: false,
      gradeList: [],
      classList: [],
      idMonth: moment().format("M"),
      grade: "",
      listMasterAbsent: [],
      loadingClass: false,
      loadingGrade: false,
      idSchoolYearActive: 0,
      itemAbsent: {
        name: "",
        real_in: "",
        real_out: "",
        color: "",
        info: ""
      },
      search: "",
      totalDay: 0,
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  created() {
    this.getGrade();
    this.getMasterAbsent();
    this.getSchoolYear();
  },
  watch: {
    init: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getData();
        }
      }
    },
    searchData(value) {
      this.handleSearchData(value);
    }
  },
  methods: {
    handleSearchData(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.search = value;
        if (!this.isMonthDisabled || this.prefix == "employee") {
          this.getData();
        } else {
          this.validateSearch();
        }
      }, doneTypingInterval);
    },
    excelFileName(worksheet) {
      const recap = i18n.t("routes.recap_absent");
      let result = "";
      let studentExcel = "";
      if (this.classList.length > 0 && this.idClass) {
        studentExcel = `${recap} ${i18n.t("app.class")} ${
          this.classList.find(item => item.id === Number(this.idClass)).name
        }${worksheet ? "" : ".xls"}`;
      }
      switch (Number(this.$route.query.tab)) {
        case 0:
          result = studentExcel;
          break;
        case 1:
          if (worksheet) {
            result = `${recap} ${i18n.t("app.teacher")}`;
          } else {
            result = `${recap} ${i18n.t("app.teacher")}.xls`;
          }
          break;
        case 2:
          if (worksheet) {
            result = `${recap} Staff`;
          } else {
            result = `${recap} Staff.xls`;
          }
          break;
        default:
          result = studentExcel;
          break;
      }
      return result;
    },
    getDataExcel() {
      const results = {
        [i18n.t("app.name")]: "name",
        [i18n.t("recap_absent.present")]: "KEHADIRAN",
        [i18n.t("recap_absent.sick")]: "SAKIT",
        [i18n.t("recap_absent.permission")]: "IZIN",
        [i18n.t("recap_absent.info_color.black")]: "LIBUR",
        Alpha: "ALPHA"
      };

      for (let i = 0; i < this.totalDay; i++) {
        results[i + 1] = {
          field: "detail",
          callback: item => {
            return colorAbsent(item[i].type).code;
          }
        };
      }

      // // Sorting Object to array
      // var header = [];
      // for (var h in results) {
      //   header.push([h, results[h]]);
      // }

      // header.sort(function(a, b) {
      //   return a[1] - b[1];
      // });

      // // Sorting Array
      // const mappingHeader = JSON.parse(JSON.stringify(header));
      // const getNameIndex = header.findIndex(e => e.includes("name"));
      // mappingHeader[0] = header[getNameIndex];
      // header.map((h, i) => {
      //   if (i + 1 > getNameIndex) {
      //     // Remove Name
      //   } else {
      //     mappingHeader[i + 1] = h;
      //   }
      // });

      // // convert to Object
      // let headerExcel = {};
      // mappingHeader.map(mh => {
      //   headerExcel[mh[0]] = mh[1];
      // });

      return results;
    },
    downloadExcel() {
      return this.rowData;
    },
    async getSchoolYear() {
      this.loadingSchoolYear = true;
      try {
        const response = await get_school_year_list(false);
        if (response.data.code) {
          let r = response.data.data;
          this.idSchoolYearActive = r.find(d => d.status == 1).id;
        }
      } catch (error) {
        console.error("getSchoolYear()\n", error);
      }
      this.loadingSchoolYear = false;
    },
    checkPrivilages: name => checkPrivilages(name),
    validateSearch(validSearch) {
      this.$emit("searchFailed", { text: this.searchData, valid: validSearch });
    },
    async getMasterAbsent() {
      let res = await masterAbsent();
      if (res.data) this.listMasterAbsent = res.data.data;
    },
    getTime: data => data?.split(" ")[1].substr(0, 5) ?? "-",
    async detailAbsent(data) {
      if (data.value) {
        let item = data.value;
        let body = {
          person: `${item.person}`,
          date: `${item.date}`
        };
        let result = await getDetailReport(body).then(res => res.data.data[0]);
        this.itemAbsent.name = result.name;
        this.itemAbsent.real_in = this.getTime(result.real_in);
        this.itemAbsent.real_out = this.getTime(result.real_out);
        this.itemAbsent.color = this.createColorAbsent(data, true).color;
        this.itemAbsent.info = this.createColorAbsent(data, true).name;
        this.isDialog();
      }
    },
    isDialog() {
      this.modelDialog = !this.modelDialog;
    },
    generateHeader(total) {
      let result = [];
      for (let i = 0; i < total; i++) {
        result.push({
          width: 70,
          field: `${i + 1}`,
          headerName: `${i + 1}`,
          cellRenderer: this.createColorAbsent,
          onCellClicked: this.detailAbsent,
          cellClass: "locked-col",
          lockPosition: true
        });
      }
      return result;
    },
    createColorAbsent(params, forDialog) {
      let color = colorAbsent(params.value?.type).color;
      let name = colorAbsent(params.value?.type).name;

      if (forDialog) {
        return { color: color, name: name };
      } else {
        return (
          "<div style='cursor: pointer; background-color: " +
          color +
          "' class='color-info mt-2'/>"
        );
      }
    },
    changeMonth(value) {
      this.idMonth = value;
      this.getData();
    },
    async getGrade() {
      this.loadingGrade = true;
      try {
        const res = await get_grade_list({ type: this.typeList });
        if (res.data.code) this.gradeList = res.data.data;
        else this.snackBar(false, res.data.message);
      } catch (error) {
        console.error("getGrade()\n", error);
      }
      this.loadingGrade = false;
    },
    async onChangeGrade(value) {
      this.classList = [];
      this.idClass = null;
      this.loadingClass = true;
      const body = {
        grade: [value],
        school_year: [this.idSchoolYearActive],
        type: this.typeList.toUpperCase()
      };
      const res = await get_class_list(body, false);
      if (res.data.code) this.classList = res.data.data;
      this.loadingClass = false;
    },
    onChangeClass(value) {
      this.idClass = value;
      this.getData();
    },
    async getData() {
      this.isMonthDisabled = !this.idClass;
      this.validateSearch(!this.isMonthDisabled);
      this.$Progress.start();
      this.loading = true;
      let body = null;
      if (this.idClass) {
        // tab student
        body = {
          grade: `${this.grade}`,
          class: this.idClass,
          year: moment().format("YYYY"),
          month: this.idMonth,
          prefix: this.prefix,
          search: this.search
        };
      } else {
        // tab teacher and staff
        switch (this.employeeType) {
          case "TEACHER":
            body = {
              employee_type: this.employeeType,
              year: moment().format("YYYY"),
              month: this.idMonth,
              prefix: "employee",
              search: this.search
            };
            break;
          case "STAFF":
            body = {
              employee_type: this.employeeType,
              year: moment().format("YYYY"),
              month: this.idMonth,
              prefix: "employee",
              search: this.search
            };
            break;
        }
      }

      if (body) {
        await getReports(body).then(async res => {
          let data = res.data.data;
          this.rowData = [];
          if (res.data.code) {
            const totalDay = res.data.totalDay;
            this.totalDay = totalDay;
            this.fields = this.getDataExcel();
            this.headerDate = this.generateHeader(totalDay);

            this.initialRecap();
            if (res.data.data && res.data.data.length > 0) {
              await data.forEach(function(v) {
                v.detail.forEach(function(value, index) {
                  v[`${index + 1}`] = value;
                });
              });
              this.rowData = res.data.total == 0 ? [] : data;
            }
          } else this.snackBar(false, res.data.message);
        });
      }

      this.$Progress.finish();
      this.loading = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    initialRecap() {
      this.defaultColDef = {
        resizable: true
      };
      this.columnDefs = [
        {
          headerName: i18n.t("recap_absent.table.name"),
          field: "name",
          pinned: "left",
          lockPinned: true,
          width: 200,
          cellClass: "locked-col",
          lockPosition: true
        },
        ...this.headerDate,
        {
          headerName: i18n.t("recap_absent.table.present"),
          field: "KEHADIRAN",
          pinned: "right",
          lockPinned: true,
          width: 50,
          cellClass: "locked-col",
          lockPosition: true
        },
        {
          headerName: i18n.t("recap_absent.table.late"),
          field: "TERLAMBAT",
          pinned: "right",
          lockPinned: true,
          width: 50,
          cellClass: "locked-col",
          lockPosition: true
        },
        {
          headerName: i18n.t("recap_absent.table.sick"),
          field: "SAKIT",
          pinned: "right",
          lockPinned: true,
          width: 50,
          cellClass: "locked-col",
          lockPosition: true
        },
        {
          headerName: i18n.t("recap_absent.table.permission"),
          field: "IZIN",
          pinned: "right",
          lockPinned: true,
          width: 50,
          cellClass: "locked-col",
          lockPosition: true
        },
        {
          headerName: i18n.t("recap_absent.table.alpha"),
          field: "ALPHA",
          pinned: "right",
          lockPinned: true,
          width: 50,
          cellClass: "locked-col",
          lockPosition: true
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.v-label {
  font-size: 14px;
}

.color-info {
  border-radius: 20px;
  width: 25px;
  height: 25px;
}
</style>
