<template>
  <v-row justify="center">
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="model"
      max-width="600px"
    >
      <v-card outlined>
        <v-card-title class="title">{{
          $t("recap_absent.detail_absent")
        }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="mb-5 mt-3">
              <div>
                {{ $t("recap_absent.dialog.name") }}
              </div>
              <div class="font-weight-bold">
                {{ itemAbsent.name }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="mb-5 mt-3">
              <div>{{ $t("recap_absent.dialog.absent_info") }}</div>
              <div class="d-flex justify-space-between flex-wrap">
                <div class="d-flex">
                  <div
                    v-bind:style="{ backgroundColor: itemAbsent.color }"
                    class="color-info mr-2"
                  />
                  <div class="font-weight-bold body-1">
                    {{ itemAbsent.info }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                {{ $t("recap_absent.dialog.hour_in") }}
              </div>
              <div class="font-weight-bold body-1">
                {{ itemAbsent.real_in }}
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                {{ $t("recap_absent.dialog.hour_out") }}
              </div>
              <div class="font-weight-bold body-1">
                {{ itemAbsent.real_out }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn outlined small dark color="primary" @click="closeDialog">{{
              $t("app.back")
            }}</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    model: {
      type: Boolean,
      required: true
    },
    itemAbsent: {
      type: Object,
      required: true
    }
  },
  watch: {},
  data() {
    return {
      modelTime: null,
      loadingBtn: false,
      exception: false,
      isAbsenEntry: true
    };
  },
  methods: {
    closeDialog() {
      this.$emit("isOpen");
    }
  }
};
</script>

<style lang="scss">
.color-info {
  border-radius: 20px;
  width: 25px;
  height: 25px;
}
</style>
