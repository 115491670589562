import i18n from "@/i18n";

export let colorsInfo = [
  {
    id: 1,
    name: i18n.t("recap_absent.info_color.black"),
    color: "#0a0a0a"
  },
  {
    id: 2,
    name: i18n.t("recap_absent.info_color.red"),
    color: "#ff0303"
  },
  {
    id: 3,
    name: i18n.t("recap_absent.info_color.purple"),
    color: "#8103ff"
  },
  {
    id: 4,
    name: i18n.t("recap_absent.info_color.orange"),
    color: "#ffc003"
  },
  {
    id: 5,
    name: i18n.t("recap_absent.info_color.green"),
    color: "#44a603"
  },
  {
    id: 6,
    name: i18n.t("recap_absent.info_color.light_green"),
    color: "#03fc20"
  },
  {
    id: 7,
    name: i18n.t("recap_absent.info_color.blue"),
    color: "#03f0fc"
  },
  {
    id: 8,
    name: i18n.t("recap_absent.info_color.yellow"),
    color: "#dbfc03"
  },
  {
    id: 9,
    name: i18n.t("recap_absent.info_color.purple_darken"),
    color: "#B388FF"
  }
];

export function colorAbsent(typeAbsent) {
  let color = "";
  let name = "";
  let code = "";
  switch (typeAbsent) {
    case 1:
      color = colorsInfo[1].color;
      name = colorsInfo[1].name;
      code = "A";
      break;
    case 2:
      color = colorsInfo[6].color;
      name = colorsInfo[6].name;
      code = "I";
      break;
    case 3:
      color = colorsInfo[2].color;
      name = colorsInfo[2].name;
      code = "PA";
      break;
    case 4:
      color = colorsInfo[5].color;
      name = colorsInfo[5].name;
      code = "AM";
      break;
    case 5:
      color = colorsInfo[4].color;
      name = colorsInfo[4].name;
      code = "AK";
      break;
    case 6:
      color = colorsInfo[7].color;
      name = colorsInfo[7].name;
      code = "S";
      break;
    case 7:
      color = colorsInfo[8].color;
      name = colorsInfo[8].name;
      code = "IT";
      break;
    case 9:
      color = colorsInfo[3].color;
      name = colorsInfo[3].name;
      code = "T";
      break;
    case 10:
      color = colorsInfo[0].color;
      name = colorsInfo[0].name;
      code = "L";
      break;
    default:
      color = "#ffffff";
      name = "-";
      code = "-";
      break;
  }

  return { color: color, name: name, code: code };
}
